import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import Container from '../components/Container'
import Layout from '../components/layouts'
import TeamCard from '../components/TeamCard'

function about({ data }) {
	return (
		<>
			<Helmet>
				<title>Classify — About</title>
				<meta
					name="description"
					content="A small development team based in London, we proudly showcase the fun-loving nerds behind Classify."
				/>
				<link rel="canonical" href="https://classify.org.uk/about" />

				<meta property="og:title" content="Classify — About" />
				<meta
					property="og:url"
					content="https://classify.org.uk/about"
				/>
				<meta
					property="og:image"
					content="https://i.imgur.com/ODTJzzm.png"
				/>
				<meta
					property="og:description"
					content="A small development team based in London, we proudly showcase the fun-loving nerds behind Classify."
				/>
				<meta property="og:type" content="website" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@classifyapp" />
				<meta property="twitter:title" content="Classify — About" />
				<meta
					name="twitter:image"
					content="https://i.imgur.com/ODTJzzm.png"
				/>
				<meta
					property="twitter:description"
					content="A small development team based in London, we proudly showcase the fun-loving nerds behind Classify."
				/>
			</Helmet>
			<Layout>
				<section id="about" className="bg-about">
					<Container no-vertical>
						<div className="grid gap-12 text-center py-12 lg:py-24">
							<h1>🧑‍💻</h1>
							<p className="text-xl">
								A small development team based in London, we
								proudly showcase the fun-loving nerds behind
								Classify.
							</p>
							<div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-12">
								{data.allWpTeamMember.nodes.map(
									(member, index) => (
										<TeamCard
											name={member.title}
											bio={member.teamMemberContent.bio}
											position={
												member.teamMemberContent
													.position
											}
											imageUrl={
												member.teamMemberContent.image
													? member.teamMemberContent
															.image.sourceUrl
													: ''
											}
											key={index}
										/>
									)
								)}
							</div>
						</div>
					</Container>
				</section>
			</Layout>
		</>
	)
}

export const pageQuery = graphql`
	query {
		allWpTeamMember {
			nodes {
				title
				teamMemberContent {
					bio
					image {
						sourceUrl
					}
					position
				}
			}
		}
	}
`

export default about
