import React from 'react'

function TeamCard({ name, bio, imageUrl, position }) {
	return (
		<div className="bg-white rounded-2xl transition-shadow duration-300 shadow-lg hover:shadow-xl flex flex-col">
			<div
				className="aspect-w-10 aspect-h-7 bg-purple bg-cover bg-center rounded-t-2xl"
				style={{
					backgroundImage: `url(${imageUrl})`,
					backgroundPosition: '10%',
				}}
			/>
			<div className="flex flex-col rounded-b-2xl px-8 py-6 text-left flex-grow">
				<span className="text-xl pb-2">{name}</span>
				<span className="text-sm pb-2">{bio}</span>
				<span className="text-black-faded mt-auto">{position}</span>
			</div>
		</div>
	)
}

export default TeamCard
